<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <div>
          <h4 class="mr-sm-4 header-tablepage">group barcode DETAIL</h4>
        </div>
        <div class="title-tabs mt-3">
          <b-row>
            <b-col md="6" class="text-left"> Information</b-col>
          </b-row>
        </div>
        <b-container class="no-gutters bg-white">
          <b-row class="pt-3">
            <b-col class="col-md-6">
              <InputText
                textFloat="Name"
                placeholder="Name"
                type="text"
                v-model="form.name"
                :isValidate="$v.form.name.$error"
                :v="$v.form.name"
                isRequired
              />
            </b-col>
          </b-row>
        </b-container>

        <div class="title-tabs">
          <b-row class="align-items-center">
            <b-col md="6" class="text-left">Barcode</b-col>
            <b-col md="6" class="text-right">
              <b-button
                size="sm"
                @click.prevent="openModal()"
                class="btn-filter mr-2"
              >
                <span class="d-none d-sm-flex align-items-center">
                  <font-awesome-icon icon="plus" class="pointer" />
                  <span class="ml-2">Create New </span>
                </span>
                <font-awesome-icon icon="plus" class="d-sm-none" /> </b-button
            ></b-col>
          </b-row>
        </div>

        <b-container class="no-gutters bg-white p-3">
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="form.details"
            :current-page="filter.page"
            :per-page="filter.take"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(status)="{ item }">
              <div :class="item.status ? 'text-success' : 'text-error'">
                {{ item.status ? "Active" : "InActive" }}
              </div>
            </template>
            <template v-slot:cell(price_start)="{ item }">
              ฿{{ item.price_start | numeral("0,0.00") }}
            </template>
            <template v-slot:cell(price_end)="{ item }">
              ฿{{ item.price_end | numeral("0,0.00") }}
            </template>
            <template v-slot:cell(gp_rate)="{ item }">
              {{ item.gp_rate }}%
            </template>
            <template v-slot:cell(action)="row">
              <font-awesome-icon
                class="cursor-pointer"
                icon="pencil-alt"
                @click="openModal(row.item)"
              />
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
          <div class="text-error" v-if="$v.form.details.$error">
            Please Input Group Barcode
          </div>
          <Pagination
            @handleChangeTake="handleChangeTake"
            :pageOptions="pageOptions"
            :filter="filter"
            :rows="form.details.length"
            @pagination="pagination"
          />
        </b-container>
      </div>
      <FooterAction
        routePath="/setting/product-setting/group-barcode"
        @submit="saveForm()"
      />
      <ModalBarcode ref="ModalBarcode" @submit="onAddBarcode"></ModalBarcode>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import ModalBarcode from "./components/ModalBarcode.vue";
import InputText from "@/components/inputs/InputText";
import { required } from "vuelidate/lib/validators";
import InputTextArea from "@/components/inputs/InputTextArea";
import requiredIf from "vuelidate/lib/validators/requiredIf";

export default {
  components: {
    OtherLoading,
    ModalBarcode,
    InputText,
    InputTextArea,
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        details: {
          required: requiredIf(function (item) {
            return item.details.length == 0;
          }),
        },
      },
    };
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      items: [],
      form: {
        name: "",
        details: [],
      },
      filter: {
        search: "",
        page: 1,
        take: 5,
      },
      fields: [
        {
          key: "gp_barcode",
          label: "Barcode",
          thClass: "w-2",
        },
        {
          key: "gp_rate",
          label: "GP%",
          thClass: "w-2",
        },
        {
          key: "price_start",
          label: "Price Start",
          thClass: "w-2",
        },
        {
          key: "price_end",
          label: "Price End",
          thClass: "w-2",
        },
        {
          key: "status",
          label: "Status",
          thClass: "w-2",
        },

        {
          key: "action",
          label: "Action",
          thClass: "w-1",
        },
      ],
      id: this.$route.params.id,
      rows: 0,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      selectedItem: [],
      deleteId: "",
      confirmMsg: "",
      allItem: [],
      rowsFormData: [],
      filteredItems: [],
      editIndex: null,
    };
  },

  async created() {
    if (this.id > 0) {
      this.isLoading = true;
      await this.getDetail();
      this.isLoading = false;
    }
  },
  methods: {
    async getDetail() {
      this.isBusy = true;
      const res = await this.axios(`GPBarcode/${this.id}`);
      this.form.details = res.data.detail.detail.map((el, index) => ({
        ...el,
        index,
      }));
      this.form.name = res.data.detail.header.name;
      this.form.id = res.data.detail.header.id;
      this.isBusy = false;
    },
    async saveForm() {
      this.$v.$touch();
      this.isLoading = true;
      if (!this.$v.$error) {
        if (this.id > 0) {
          const res = await this.axios.put(
            `GPBarcode/UpdateGPBarcode`,
            this.form
          );
          if (res.data.result === 1) {
            this.successAlert();
            this.$router.push(`/setting/product-setting/group-barcode`);
          } else {
            this.errorAlert(res.data.message);
          }
        } else {
          const res = await this.axios.post(
            `GPBarcode/CreateGPBarcode`,
            this.form
          );
          if (res.data.result === 1) {
            this.successAlert();
            this.$router.push(`/setting/product-setting/group-barcode`);
          } else {
            this.errorAlert(res.data.message);
          }
        }
      }

      this.isLoading = false;
    },
    openModalDelete(id) {
      this.deleteId = id;
      this.confirmAlert().then((val) => {
        if (val.isConfirmed) {
          this.deleteProduct();
        }
      });
      this.deleteId = id;
    },
    async deleteProduct() {
      this.allItem = this.allItem.filter((x) => x.id !== this.deleteId);
      this.filteredItems = this.filteredItems.filter(
        (x) => x.id !== this.deleteId
      );
      this.selectedItem = this.selectedItem.filter(
        (x) => x.id !== this.deleteId
      );
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    onAddBarcode(items) {
      this.isBusy = true;

      this.$nextTick(() => {
        if (this.editIndex != null) {
          let temp = JSON.parse(JSON.stringify(this.form.details));
          temp[this.editIndex] = items;
          this.form.details = temp;
        } else {
          items.index = this.form.details.length;
          this.form.details.push(items);
        }
        setTimeout(() => {
          this.isBusy = false;
        }, 100);
      });
    },
    openModal(item) {
      this.editIndex = null;
      let _temp = null;
      if (item) {
        this.editIndex = item.index;
        _temp = JSON.parse(JSON.stringify(item));
      }
      this.$refs.ModalBarcode.show(_temp);
    },
    handleSearch() {
      const searchText = this.filter.search.toLowerCase();
      if (searchText) {
        this.filteredItems = this.allItem.filter((item) =>
          item.name.toLowerCase().includes(searchText)
        );
      } else {
        this.filteredItems = [...this.allItem];
      }
      this.filter.page = 1;
      this.updateDisplayedItems();
    },

    pagination(val) {
      this.filter.page = val;
      this.updateDisplayedItems();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    updateDisplayedItems() {
      this.isBusy = true;
      const start = (this.filter.page - 1) * this.filter.take;
      const end = start + this.filter.take;

      this.items = this.filteredItems.slice(start, end);
      this.rows = this.filteredItems.length;
      this.isBusy = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-gray {
  color: #939393;
}
.icon-size {
  font-size: 20px;
  color: #575757;
}
</style>
