<template>
  <b-modal v-model="showModal" ref="modalSelectProduct" centered @hidden="hide">
    <template #modal-header>Barcode Detail</template>
    <b-row>
      <b-col cols="6">
        <InputText
          textFloat="Barcode"
          placeholder="Barcode"
          isRequired
          v-model="$v.form.gp_barcode.$model"
          :isValidate="$v.form.gp_barcode.$error"
          :v="$v.form.gp_barcode"
        />
      </b-col>
      <b-col cols="6">
        <InputText
          textFloat="GP Rate"
          placeholder="GP Rate"
          isRequired
          v-model="$v.form.gp_rate.$model"
          :isValidate="$v.form.gp_rate.$error"
          :v="$v.form.gp_rate"
        />
      </b-col>
      <b-col cols="6">
        <InputText
          textFloat="Price Start"
          placeholder="Price Start"
          isRequired
          v-model="$v.form.price_start.$model"
          :isValidate="$v.form.price_start.$error"
          :v="$v.form.price_start"
        />
      </b-col>
      <b-col cols="6">
        <InputText
          textFloat="Price End"
          placeholder="Price End"
          isRequired
          v-model="$v.form.price_end.$model"
          :isValidate="$v.form.price_end.$error"
          :v="$v.form.price_end"
        />
      </b-col>
      <b-col cols="12">
        <InputTextArea
          textFloat="Description"
          placeholder="Description"
          v-model="form.description"
        />
      </b-col>
      <b-col cols="12">
        <b-form-checkbox
          switch
          v-model="form.status"
          class="radio-active"
          size="lg"
        >
          <span class="ml-2 main-label">
            {{ form.status ? "Active" : "Inactive" }}
          </span>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-row no-gutters class="w-100">
        <b-col cols="6">
          <b-button @click.prevent="hide(1)" class="border-btn btn-width"
            >Cancel</b-button
          >
        </b-col>
        <b-col cols="6" class="text-right">
          <b-button class="submit-btn btn-width" @click.prevent="submit"
            >Save</b-button
          >
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { required, requiredIf, url, minValue } from "vuelidate/lib/validators";
import InputSelect from "@/components/inputs/InputSelect";
// import minValue from "vuelidate/lib/validators/minValue";
const maxValuePercent = (value, vm) => {
  if (value > 100) return false;
  return true;
};
export default {
  components: {
    InputSelect,
  },
  props: {},
  validations() {
    return {
      form: {
        gp_barcode: { required },
        gp_rate: { required, minValue: minValue(1), maxValuePercent },
        price_start: { required },
        price_end: { required, minValue: minValue(1) },
      },
    };
  },
  data() {
    return {
      showModal: false,
      form: {
        id: 0,
        gp_barcode: "",
        gp_rate: 0,
        price_start: 0,
        price_end: 0,
        description: "",
        status: true,
      },
    };
  },
  async mounted() {},
  watch: {},
  methods: {
    async show(product_list) {
      if (product_list) this.form = product_list;
      this.showModal = true;
    },

    hide(status) {
      this.form = {
        id: 0,
        gp_barcode: "",
        gp_rate: 0,
        price_start: 0,
        price_end: 0,
        description: "",
        status: true,
      };
      this.showModal = false;
    },

    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.$emit("submit", this.form);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
  font-weight: 600;
}
::v-deep .modal-dialog {
  max-width: 800px;
}
::v-deep .input-group-text {
  padding: 0;
  background-color: transparent;
}
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
  text-align: center;
}
::v-deep .modal-footer {
  justify-content: space-between;
}
.btn-save {
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.btn-cancel {
  color: #fff;
  background-color: #39424e;
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.pic-box {
  margin: auto !important;
  width: 50%;
  padding-top: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
::v-deep .table-width {
  width: 150px;
}
</style>
